import React from "react";
function BlogHero() {
  return (
    <section className="max-w-screen-2xl px-4 md:px-8 mx-auto">
      <div className="flex flex-wrap justify-between mb-8 md:mb-16">
        <div className="w-full lg:w-1/3 flex flex-col justify-center lg:pt-48 lg:pb-24 mb-6 sm:mb-12 lg:mb-0">
          <h1 className="text-gray-800 text-left text-4xl sm:text-5xl md:text-6xl font-bold mb-4 md:mb-8">
            MVP Insights
            <br />
          </h1>

          <p className="max-w-md text-gray-500 xl:text-lg leading-relaxed">
            " The more we share with you, the better you understand us "
          </p>
        </div>

        <div className="w-full lg:w-2/3 flex mb-12 md:mb-16">
          <div className="bg-gray-100 rounded-lg shadow-lg overflow-hidden relative z-10 top-12 md:top-16 left-12 md:left-16 -ml-12 lg:ml-0">
            <img
              src="https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
              loading="lazy"
              alt="Blog banner"
              className="w-full h-full object-cover object-center"
            />
          </div>

          <div className="bg-gray-100 rounded-lg shadow-lg overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1580894742597-87bc8789db3d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8aW5mb3JtYXRpb258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
              loading="lazy"
              alt="Blog banner"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogHero;
