import React from "react";
import { graphql, Link } from "gatsby";
import BlogHero from "../components/MVPInsightsPage/BlogHero";
import SiteSEO from "../components/siteSEO";
export default function ({ data: { allGraphCmsPost } }) {
  return (
    <div className="py-16 bg-gray-50">
      <SiteSEO
        title={"MVP Insights | MVP Tribe"}
        description={
          "Welcome to MVP Insights! We are here to share the working knowledge of how, what and why in startup domain, who is doing what and how new companies do some things."
        }
        keywords={
          "MVP Development , Blog, MVP Insights, Posts, Techblog, business"
        }
        image={""}
      />
      <BlogHero />
      <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div className="grid gap-12 md:grid-cols-2">
          {allGraphCmsPost.nodes.map((post) => {
            return (
              <div className="group space-y-4 shadow">
                <img
                  src={post.coverImage.url}
                  alt="art cover"
                  loading="lazy"
                  width="1000"
                  height="667"
                  className="h-64 w-full object-cover object-top rounded-lg shadow transition duration-500 group-hover:rounded-3xl"
                />
                <div className="space-y-2 px-3">
                  <span className="text-cyan-500">
                    <strong>Posted on:</strong> {post.date}
                  </span>
                  <div className="space-y-4">
                    <h4 className="text-2xl font-semibold text-gray-700">
                      {post.title}
                    </h4>
                    <p className="text-gray-600">{post.excerpt}</p>
                  </div>
                </div>
                <Link
                  to={`/posts/${post.slug}`}
                  className="block bg-primary py-1 px-2 ml-3 rounded shadow inline-block w-max text-white text-decoration-none px-3 mb-2"
                >
                  Read more
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export const indexPageQuery = graphql`
  {
    allGraphCmsPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        date: formattedDate
        excerpt
        slug
        title
        coverImage {
          url
        }
      }
    }
  }
`;
